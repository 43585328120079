import { Filter } from 'fluent-ffmpeg';
import { PAGE_SIZE } from '../config';

export interface ApiWrapper {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | "PATCH";
  url: string;
  body?: {} | FormData;
  params?: {};
  accessToken?: string | null;
  isFileUpload?: boolean;
  onApiError?: (error: ApiError | Error) => boolean | Promise<boolean>;
}

export interface ApiErrorResponse {
  error: {
    statusCode: number;
    message: string;
    name: string;
    path?: string;
  };
}

export class ApiError extends Error {
  public statusCode: number;
  public name: string;
  public path?: string;

  constructor({ error }: ApiErrorResponse) {
    super(error.message);

    this.statusCode = error.statusCode;
    this.name = error.name;
    this.path = error.path;
  }
}

export interface BadgeAutocompleteVars {
  text: string;
}

export interface AutocompleteResponse {
  results: string[];
}

export interface MenuItem {
  id: number;
  name: string;
  imageId?: number;
  topListings?: ListingsSearchResponse[];
}

export interface MenuItemsResponse {
  menuItems: MenuItem[];
}

export interface BadgeListingRequestsResponse {
  id: number;
  name: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
}

export interface BadgeAutocompleteResponse {
  results: BadgeListingRequestsResponse[];
}

export interface HashtagAutocompleteResponse {
  results: HashtagListingResponse[];
}

export interface ListingGetBadgesResponse {
  badges: BadgeListingRequestsResponse[];
}

export type ListingUpdateBadgesResponse = ListingGetBadgesResponse;

export interface HashtagListingResponse {
  linkId: number;
  id: number;
  name: string;
  imageId: number;
}

export interface ListingGetHashtagsResponse {
  hashtags: HashtagListingResponse[];
}

export interface ListingUpdateHashtagsVars {
  listingId: number;
  hashtagIds: number[];
}

export type ListingUpdateHashtagsResponse = ListingGetHashtagsResponse;
export type HashtagAutocompleteVars = BadgeAutocompleteVars;

export interface HashtagItem {
  id: number;
  name: string;
  status: HashtagStatus;
  isFeatured: boolean;
  isMenu: boolean;
  imageId?: number;
}

export type HashtagUpdateResponse = HashtagItem;
export type HashtagCreateResponse = HashtagItem;
export type HashtagGetResponse = HashtagItem;

export interface HashtagPaginationItem {
  id: number;
  name: string;
  status: HashtagStatus;
  totalListings?: number;
}

export interface SubHashtagGetResponse {
  subHashtags: HashtagPaginationItem[];
}

export type SubHashtagUpdateResponse = SubHashtagGetResponse;

export interface SubHashtagUpdateVars {
  hashtagId: number;
  subHashtagIds: number[];
}

export interface HashtagPaginationResponse {
  totalCount: number;
  hashtags: HashtagPaginationItem[];
}

export interface BadgeSearchResponse {
  id: number;
  name: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
}

export interface ListingsSearchResponse {
  id: number;
  fullName: string;
  facebookProfile?: string;
  instagramProfile?: string;
  tiktokProfile?: string;
  podcastURL?: string;
  twitterHandle: string;
  twitterFollowers: number;
  threadsProfile?: string;
  goodreadsProfile?: string;
  mailingListProfile?: string;
  reviewCount: number;
  reviewRating: number;
  imageId?: number;
  productImageId?: number;
  productUrl?: string;
  imageStatus?: ImageUploadStatus;
  coverStatus?: CoverUploadStatus;
  productImageStatus?: ImageUploadStatus;
  badges: BadgeSearchResponse[];
  hasCurrentUserReviewed: boolean;
  isClaimed: boolean;
  productAudioId?: number;
  currentUserRating: number;
  productAudioStatus?: AudioUploadStatus;
}

export interface HashtagsSearchResponse {
  id: number;
  name: string;
  imageId?: number;
  totalListings: number;
}

export interface HashtagsAndListingsResponse {
  listings: ListingsSearchResponse[];
  hashtags: HashtagsSearchResponse[];
}

export interface SubHashtagsResponse {
  hashtag: HashtagsSearchResponse;
  subHashtags: Omit<HashtagsSearchResponse, 'imageId'>[];
  listings: ListingsSearchResponse[];
}

export interface PopularHashtagsResponse {
  featuredHashtags: HashtagsSearchResponse[];
  newHashtags: HashtagsSearchResponse[];
}

export interface PopularListingsPaginationResponse {
  totalCount: number;
  newListings: ListingsSearchResponse[];
}

export interface SignupResponse {
  id: string;
}

export interface SignupVars {
  name: string;
  email: string;
  password: string;
}

export interface LoginVars {
  email: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export enum UserRole {
  user = 'user',
  admin = 'admin',
}

export interface MeResponse {
  id: string;
  name: string;
  email: string;
  roles: UserRole[];
  isSubscription: boolean,
  listingImageId?: number;
  delegatedId?: string;
  countMissingElements?: number;
  countMissingEmailRightsholder?: number;
  countMissingProperty?: number;
  countMissingPropertyLinks?: number;
  countMissingPropertySplits?: number;
  isDelinquent?: boolean;
  isMissingErrors?: boolean;
  isCompanySettings?: boolean;
  countMissingErrors?: number;
  plan?: string;
}

interface ListingClaimFullResponse {
  id: number;
  listingId: number;
  twitterHandle: string;
}

export interface MyListingResponse {
  listingId: number | null;
  listingClaims: ListingClaimFullResponse[];
}

export interface LogoutResponse {
  success: boolean;
}

export interface ListingReviewVars {
  listingId: number;
  rating: number;
}

export interface ListingReviewResponse {
  id: number;
  userId: string;
  listingId: number;
  rating: number;
  createdAt: Date;
}

export interface RefreshVars {
  refreshToken: string;
}

export interface RefreshResponse {
  accessToken: string;
  refreshToken: string;
}

export interface ListingSuggestionVars {
  fullName: string;
  twitterHandle: string;
  hashtagName: string;
}

export interface ListingSuggestionResponse {
  listingId: string;
}

export enum ImageUploadStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum AudioUploadStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum ImportUploadStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum CoverUploadStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum ContentUploadStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum RightsholderUploadStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum HandleStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum HashtagStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum BadgeRequestStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export interface HashtagPaginationVars {
  skipCount: number;
  pageSize: PAGE_SIZE;
  orderAsc: boolean;
  status?: HashtagStatus;
  text?: string;
  includeTotalListings?: boolean;
}

export interface ListingPaginationVars {
  skipCount: number;
  pageSize: PAGE_SIZE;
  orderAsc: boolean;
  twitterHandleStatus?: HandleStatus;
  listingImageStatus?: ImageUploadStatus;
  productImageStatus?: ImageUploadStatus;
  productAudioStatus?: AudioUploadStatus;
  text?: string;
}

export interface ListingPaginationItem {
  id: number;
  fullName: string;
  facebookProfile: string;
  instagramProfile: string;
  tiktokProfile: string;
  podcastURL: string;
  twitterHandle: string;
  twitterFollowers: number;
  twitterHandleStatus: HandleStatus;
  threadsProfile: string;
  goodreadsProfile: string;
  mailingListProfile: string;
  createdAt: Date;
  imageId?: number;
  productImageId?: number;
  productUrl?: string;
  imageStatus?: ImageUploadStatus;
  productImageStatus?: ImageUploadStatus;
  productAudioId?: number;
  productAudioStatus?: AudioUploadStatus;
}

export interface ListingPaginationResponse {
  totalCount: number;
  listings: ListingPaginationItem[];
}

export type ListingUpdateResponse = ListingPaginationItem;
export type ListingCreateResponse = ListingPaginationItem;
export type ListingGetResponse = ListingPaginationItem;

export interface ListingUpdateVars {
  listingId: number;
  fullName: string;
  facebookProfile?: string;
  instagramProfile?: string;
  tiktokProfile?: string;
  podcastURL?: string;
  twitterHandle: string;
  twitterHandleStatus?: HandleStatus;
  threadsProfile?: string;
  goodreadsProfile?: string;
  mailingListProfile?: string;
  imageId?: number;
  productImageId?: number;
  productUrl?: string;
  imageStatus?: ImageUploadStatus;
  productImageStatus?: ImageUploadStatus;
  productAudioId?: number;
  productAudioStatus?: AudioUploadStatus;
}

export type ListingCreateVars = Omit<ListingUpdateVars, 'listingId'>;

export interface HashtagUpdateVars {
  hashtagId: number;
  name: string;
  status?: HashtagStatus;
}

export type HashtagCreateVars = Omit<HashtagUpdateVars, 'hashtagId'>;

export interface ListingUpdateBadgesVars {
  listingId: number;
  badgeIds: number[];
}

export interface BadgePaginationItem {
  id: number;
  name: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
  totalListings?: number;
}

export interface BadgePaginationVars {
  skipCount: number;
  pageSize: PAGE_SIZE;
  orderAsc: boolean;
  includeTotalListings?: boolean;
}

export interface PopularListingsPaginationVars {
  text?: string;
  skipCount: number;
  pageSize: PAGE_SIZE;
}

export interface PaginationVars {
  skipCount: number;
  pageSize: PAGE_SIZE;
}

export interface SubhashtagPaginationVars {
  hashtagName?: string;
  skipCount: number;
  pageSize: PAGE_SIZE;
}

export interface BadgePaginationResponse {
  totalCount: number;
  badges: BadgePaginationItem[];
}

export interface BadgeItem {
  id: number;
  name: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
}

export type BadgeUpdateResponse = BadgeItem;
export type BadgeCreateResponse = BadgeItem;
export type BadgeGetResponse = BadgeItem;

export interface BadgeUpdateVars {
  badgeId: number;
  name: string;
}

export type BadgeCreateVars = Omit<BadgeUpdateVars, 'badgeId'>;

export interface BadgeRequestPaginationVars {
  skipCount: number;
  pageSize: PAGE_SIZE;
  orderAsc: boolean;
  status?: BadgeRequestStatus;
}

export interface BadgeRequestPaginationItem {
  id: number;
  badgeName: string;
  fullName: string;
  status: BadgeRequestStatus;
  createdAt: Date;
}

export interface BadgeRequestPaginationResponse {
  totalCount: number;
  badgeRequests: BadgeRequestPaginationItem[];
}

export interface BadgeRequestItem {
  id: number;
  badgeId: number;
  badgeName: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
  listingFullName: string;
  listingTwitterHandle: string;
  proofUrl: string;
  comment: string;
  status: BadgeRequestStatus;
  createdAt: Date;
}

export type BadgeRequestGetResponse = BadgeRequestItem;
export type BadgeRequestUpdateResponse = BadgeRequestItem;
export type BadgeRequestCreateResponse = BadgeRequestItem;

export interface BadgeRequestUpdateVars {
  badgeRequestId: number;
  status: BadgeRequestStatus;
}

export interface BadgeRequestUpdateVars {
  badgeRequestId: number;
  status: BadgeRequestStatus;
}

export interface BadgeRequestCreateVars {
  listingId: number;
  badgeId: number;
  proofUrl: string;
  comment?: string;
}

export interface BadgeRequestsResponse {
  id: number;
  badgeId: number;
  badgeName: string;
  imageId?: number;
  iconName?: string;
  iconColor?: string;
  status: BadgeRequestStatus;
  createdAt: Date;
}

export interface ListingBadgeRequestsResponse {
  badgeRequests: BadgeRequestsResponse[];
}

export enum ImageUploadTreatment {
  badge = 'badge',
  hashtag = 'hashtag',
  listing = 'listing',
  rightsholder = 'rightsholder',
  book = 'book',
  audiobook = 'audiobook',
  logo = 'logo',
}

enum ImageUploadAccess {
  public = 'public',
  private = 'private',
}

export interface ImageUploadResponse {
  id: number;
  uploadCompleted: boolean;
  treatment: ImageUploadTreatment;
  access: ImageUploadAccess;
  createdAt: Date;
}

export interface ImageUploadVars {
  treatment: ImageUploadTreatment;
  file: File;
}

export interface AudioUploadVars {
  file: File;
}

export type AudioUploadResponse = Omit<ImageUploadResponse, 'treatment'>;

export interface ImportUploadVars {
  file: File;
}

export interface CoverUploadVars {
  treatment: ImageUploadTreatment;
  file: File;
}

export interface LogoUploadVars {
  treatment: ImageUploadTreatment;
  file: File;
}

export interface RightsholderImageUploadVars {
  treatment?: ImageUploadTreatment;
  file: File;
}

export type ImportUploadResponse = Omit<ImageUploadResponse, 'treatment'>;

export type CoverUploadResponse = Omit<ImageUploadResponse, 'treatment'>;

export type LogoUploadResponse = Omit<ImageUploadResponse, 'treatment'>;

export type RightsholderImageUploadResponse = Omit<ImageUploadResponse, 'treatment'>;
export interface RequestPasswordResetVars {
  email: string;
}

export interface RequestPasswordResetResponse {
  success: boolean;
}

export type ResetPasswordResponse = RequestPasswordResetResponse;

export interface ResetPasswordVars {
  verificationToken: string;
  password: string;
}

export interface ContactUsVars {
  name: string;
  email: string;
  message: string;
}

export interface UserPaginationVars {
  skipCount: number;
  pageSize: PAGE_SIZE;
  orderAsc: boolean;
  role?: UserRole;
  text?: string;
}
export interface SiteSettingsFilter {
  offset: number;
  limit: number;
  skip: number,
  order: string;
  where: any;
}
export interface SiteSettingsPaginationVars {
  // filter: SiteSettingsFilter;
  filter: string;
}
export interface SiteSettingsCountPaginationVars {
  // filter: SiteSettingsFilter;
  where: string;
}

export interface CountResponse {
  count: number;
}

export interface UserPaginationResponse {
  totalCount: number;
  users: UserPaginationItem[];
}
export interface SiteSettingsPaginationResponse {
  totalCount: number;
  // siteSettings: SiteSettingsPaginationItem[];
  siteSettings: SiteSettingsItem[];
}
export interface UserPaginationItem {
  id: string;
  name: string;
  email: string;
}

export interface UserItem {
  id: string;
  name: string;
  email: string;
  roles: UserRole[];
}

export interface SiteSettingsItem {
  id: string;
  settingName: string;
  settingValue: string;
}

export interface AddSiteSettingsItem {
  settingName: string;
  settingValue: string;
}

export interface FAQItem {
  id: string;
  settingName: string;
  settingValue: string;
}

export interface AddFAQItem {
  settingName: string;
  settingValue: string;
}

export interface UserUpdateVars {
  userId: string;
  roles: UserRole[];
}

export interface SiteSettingsUpdateVars {
  siteSettingsId: string;
}

export interface UserUpdateVars {
  userId: string;
  roles: UserRole[];
}

export interface SiteSettingsUpdateVars {
  siteSettingsId: string;
}

export interface FAQUpdateVars {
  faqId: number;
}

export interface RightsholderUpdateVars {
  rightsholderId: number;
}

export interface PropertyUpdateVars {
  propertyId: number;
}

export type UserGetResponse = UserItem;
export type UserUpdateResponse = UserItem;
export type SiteSettingsUpdateResponse = SiteSettingsItem;

export interface ListingClaimResponse {
  listingId: number;
  secret: string;
}

export interface ListingClaimVars {
  twitterHandle: string;
}

export interface TwitterNameVars {
  twitterHandle: string;
}

export interface TwitterNameResponse {
  name: string;
}

export interface AllFAQData {
  id: number;
  question: string;
  answer: string;
}

export interface FaqSubmitValue {
  answer: string;
  question: string;
}

export enum Prices {
  One = 'price_1MhAMgIgPwyRlc7b89gCrHYn',
  Two = 'price_1MhAO2IgPwyRlc7bmQUJDjVn',
  Three = 'price_1MhAPvIgPwyRlc7bWybYpHOC',
  Four = 'price_1LSHVkIgPwyRlc7bVY3syhAd',
}

export type Product = {
  id: number;
  delinquent: boolean;
  email: string;
  price: string;
  subscriptionId: string;
}

export type ProductSettings = {
  isSubscribe: boolean;
  id: number;
  subscriptionId: string;
  isActive: boolean;
}

export type Subscribe = {
  price: string;
  period: string;
  title: string;
  description: {
    checked: boolean;
    value: string;
  }[];
  product: ProductSettings,
  setProduct: Function,
  unsubscribeHandler: () => void,
  unsubscribeBtnTitle: string,
  subscribeHandler: (mail: string) => void,
  subscribeBtnTitle: string,
}

export type FileResponse = {
  manualSalesEntryResponse: Array<any>;
  details?: Object;
  message?: string;
  error?: Object;
}

export interface ManualsalesentryForm {
  currencycountrycodes: [];
  manualsalesentries: [];
  producttypes: [];
  onClose: () => void;
  refetch: () => void;
  manalsalesentryId?: number;
  userId?: string | undefined;
}

export interface ProcessedMethods {
  getProcessedIndex: (index: number) => void;
  countUploadedFiles: () => void;
}

export interface BalancesForm {
  refetch: () => void;
  onClose: () => void;
  rightsholders: Array<{
    id: string;
    fullName: string;
  }>;
  balances: Array<any>;
  userId?: string;
  balanceId?: number;
}

export interface LinkShortenerForm {
  refetch: () => void;
  onClose: () => void;
  userId?: string;
  linkShorteners: Array<any>;
  linkShortenerId: number;
}

export interface LinkShortenersForm {
  refetch: () => void;
  onClose: () => void;
  userId?: string;
  linkShortenerId?: number;
}

export type CurrencycountrycodesType = {
  id: number;
  country: string;
  currencyCode: string;
  countryCode2: string;
  countryCode3: string;
}[];
