import {isServerSide, removeUndefinedFields} from '../utils/common';
import {api, storageKeys} from '@/common/config';
import {
  ApiError,
  ApiErrorResponse,
  ApiWrapper,
  AutocompleteResponse,
  PopularHashtagsResponse,
  HashtagsAndListingsResponse,
  ListingUpdateResponse,
  ListingUpdateVars,
  ListingPaginationResponse,
  ListingPaginationVars,
  ListingReviewResponse,
  ListingReviewVars,
  ListingSuggestionResponse,
  ListingSuggestionVars,
  LoginResponse,
  LoginVars,
  LogoutResponse,
  MenuItemsResponse,
  MeResponse,
  RefreshResponse,
  RefreshVars,
  SignupResponse,
  SignupVars,
  SubHashtagsResponse,
  ListingCreateResponse,
  ListingCreateVars,
  ListingGetResponse,
  BadgeAutocompleteResponse,
  BadgeAutocompleteVars,
  ListingGetBadgesResponse,
  ListingUpdateBadgesResponse,
  ListingUpdateBadgesVars,
  ListingGetHashtagsResponse,
  ListingUpdateHashtagsVars,
  ListingUpdateHashtagsResponse,
  HashtagAutocompleteVars,
  HashtagAutocompleteResponse,
  HashtagPaginationVars,
  HashtagPaginationResponse,
  HashtagCreateResponse,
  HashtagGetResponse,
  HashtagUpdateResponse,
  HashtagCreateVars,
  HashtagUpdateVars,
  SubHashtagGetResponse,
  SubHashtagUpdateResponse,
  SubHashtagUpdateVars,
  BadgePaginationResponse,
  BadgePaginationVars,
  BadgeCreateResponse,
  BadgeGetResponse,
  BadgeUpdateResponse,
  BadgeCreateVars,
  BadgeUpdateVars,
  BadgeRequestGetResponse,
  BadgeRequestPaginationResponse,
  BadgeRequestPaginationVars,
  BadgeRequestUpdateResponse,
  BadgeRequestUpdateVars,
  MyListingResponse,
  BadgeRequestCreateResponse,
  BadgeRequestCreateVars,
  ListingBadgeRequestsResponse,
  ImageUploadResponse,
  ImageUploadVars,
  RequestPasswordResetVars,
  RequestPasswordResetResponse,
  ResetPasswordResponse,
  ResetPasswordVars,
  UserPaginationResponse,
  UserPaginationVars,
  UserGetResponse,
  UserUpdateResponse,
  UserUpdateVars,
  ListingClaimResponse,
  ListingClaimVars,
  PopularListingsPaginationResponse,
  PopularListingsPaginationVars,
  TwitterNameResponse,
  TwitterNameVars,
  AudioUploadResponse,
  AudioUploadVars,
  ImportUploadResponse,
  ImportUploadVars,
  SubhashtagPaginationVars,
  PaginationVars,
  ContactUsVars,
  SiteSettingsPaginationVars,
  SiteSettingsUpdateVars,
  SiteSettingsItem,
  SiteSettingsCountPaginationVars,
  CountResponse,
  AddSiteSettingsItem,
  RightsholderImageUploadVars,
  RightsholderImageUploadResponse,
} from './types';
import {storage} from '../utils/storage';

async function apiWrapper<T>(options: ApiWrapper): Promise<T> {
  const {method, body, url, params, accessToken, isFileUpload, onApiError} =
    options;
  const isServer = isServerSide();
  const baseUrl = isServer ? api.localBaseUrl : '';
  const urlParams = new URLSearchParams(params);
  const response = await fetch(`${baseUrl}${url}?${urlParams.toString()}`, {
    method,
    headers: {
      ...(!isFileUpload && {'Content-Type': 'application/json'}),
      ...(Boolean(accessToken) && {authorization: `Bearer ${accessToken}`}),
    },
    ...(!isServer && {credentials: 'same-origin'}),
    ...(body && {
      body: isFileUpload ? (body as FormData) : JSON.stringify(body),
    }),
  });
  const payload = await response.json();

  if (!response.ok) {
    const error: ApiError | Error = payload.error
      ? new ApiError(payload as ApiErrorResponse)
      : new Error('There was an issue, please try again.');

    if (onApiError) {
      const result = onApiError(error);
      const promise = result as PromiseLike<boolean>;
      let retry = false;

      if (typeof promise.then === 'function') {
        retry = await result;
      } else {
        retry = result as boolean;
      }

      if (retry) {
        const newAccessToken = storage.getItem(storageKeys.accessToken);

        if (!newAccessToken) {
          console.warn({
            message:
              'accessToken is not available in storage after refresh flow',
          });
          throw error;
        }

        return await apiWrapper({
          ...options,
          accessToken: newAccessToken,
          onApiError: () => {
            console.warn({
              type: 'onApiErrorRetry',
              message:
                'there was an error after an api error retry. This is ok since there is retry logic at the react query level',
            });
            return false;
          },
        });
      }
    }

    throw error;
  }

  return payload;
}

async function apiOuterWrapper<T>(options: any): Promise<T> {
  const {method, url, params, isFileUpload, onApiError} = options;
  const isServer = isServerSide();
  const urlParams = new URLSearchParams(params);
  const fetchUrl = `${url}?${urlParams.toString()}`;
  const response = await fetch(fetchUrl, {
    method,
    headers: {
      ...(!isFileUpload && {'Content-Type': 'application/json'}),
    },
    ...(!isServer && {credentials: 'same-origin'}),
  });
  const payload = await response.json();

  if (!response.ok) {
    const error: ApiError | Error = payload.error
      ? new ApiError(payload as ApiErrorResponse)
      : new Error('There was an issue, please try again.');

    if (onApiError) {
      const result = onApiError(error);
      const promise = result as PromiseLike<boolean>;
      let retry = false;

      if (typeof promise.then === 'function') {
        retry = await result;
      } else {
        retry = result as boolean;
      }

      if (retry) {
        const newAccessToken = storage.getItem(storageKeys.accessToken);

        if (!newAccessToken) {
          console.warn({
            message:
              'accessToken is not available in storage after refresh flow',
          });
          throw error;
        }

        return await apiWrapper({
          ...options,
          accessToken: newAccessToken,
          onApiError: () => {
            console.warn({
              type: 'onApiErrorRetry',
              message:
                'there was an error after an api error retry. This is ok since there is retry logic at the react query level',
            });
            return false;
          },
        });
      }
    }

    throw error;
  }

  return payload;
}

export function createApiClient(
  getAccessToken: () => string | null | undefined,
  onApiError?: (error: ApiError | Error) => boolean | Promise<boolean>,
) {
  return {
    images: {
      upload: async ({treatment, file}: ImageUploadVars) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiWrapper<ImageUploadResponse>({
          url: '/api/images',
          onApiError,
          method: 'POST',
          params: {treatment},
          body: formData,
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    audios: {
      upload: async ({file}: AudioUploadVars) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiWrapper<AudioUploadResponse>({
          url: '/api/audios',
          onApiError,
          method: 'POST',
          body: formData,
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    imports: {
      upload: async ({file}: ImportUploadVars) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiWrapper<ImportUploadResponse>({
          url: '/api/imports',
          onApiError,
          method: 'POST',
          body: formData,
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    users: {
      get: async (userId: string) => {
        return apiWrapper<UserGetResponse>({
          url: `/api/users/${userId}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      update: async ({userId, ...body}: UserUpdateVars) => {
        return apiWrapper<UserUpdateResponse>({
          url: `/api/users/${userId}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      pagination: async (params: UserPaginationVars) => {
        return apiWrapper<UserPaginationResponse>({
          url: '/api/users',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },

      requestPasswordReset: async (body: RequestPasswordResetVars) => {
        return apiWrapper<RequestPasswordResetResponse>({
          url: '/api/users/request-password-reset',
          // onApiError,
          method: 'POST',
          body,
        });
      },
      resetPassword: async (body: ResetPasswordVars) => {
        return apiWrapper<ResetPasswordResponse>({
          url: '/api/users/reset-password',
          // onApiError,
          method: 'POST',
          body,
        });
      },
      myListing: async () => {
        return apiWrapper<MyListingResponse>({
          url: '/api/users/my-listing',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      me: async () => {
        return apiWrapper<MeResponse>({
          url: '/api/users/me',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      logout: async () => {
        return apiWrapper<LogoutResponse>({
          url: '/api/users/logout',
          // onApiError,
          method: 'POST',
        });
      },
      login: async (body: LoginVars) => {
        return apiWrapper<LoginResponse>({
          url: '/api/users/login',
          // onApiError,
          method: 'POST',
          body,
        });
      },
      signUp: async (body: SignupVars) => {
        return apiWrapper<SignupResponse>({
          url: '/api/users/signup',
          // onApiError,
          method: 'POST',
          body,
        });
      },
      refresh: async (body: RefreshVars) => {
        return apiWrapper<RefreshResponse>({
          url: '/api/users/refresh',
          // onApiError,
          method: 'POST',
          body,
        });
      },
      contactUs: async (body: ContactUsVars) => {
        return apiWrapper<ResetPasswordResponse>({
          url: '/api/users/contact-us',
          // onApiError,
          method: 'POST',
          body,
        });
      },
      delegatedUser: async ({...body}: any) => {
        if (!body?.userId) {
          return null;
        }

        return apiWrapper<any>({
          url: '/api/users/delegated-user',
          onApiError,
          method: 'GET',
          params: body,
          accessToken: getAccessToken(),
        });
      },
    },
    reviews: {
      rateListing: async (body: ListingReviewVars) => {
        return apiWrapper<ListingReviewResponse>({
          url: '/api/reviews',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
    },
    listings: {
      getTwitterName: async ({
        twitterHandle,
      }: TwitterNameVars): Promise<TwitterNameResponse> => {
        return await apiWrapper<TwitterNameResponse>({
          url: `/api/listings/twitter-name/${twitterHandle}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      claim: async ({
        twitterHandle,
      }: ListingClaimVars): Promise<ListingClaimResponse> => {
        return apiWrapper<ListingClaimResponse>({
          url: `/api/listings/${twitterHandle}/claim`,
          onApiError,
          method: 'POST',
          accessToken: getAccessToken(),
        });
      },
      getBadgeRequests: async (
        listingId: number,
      ): Promise<ListingBadgeRequestsResponse> => {
        const response = await apiWrapper<ListingBadgeRequestsResponse>({
          url: `/api/listings/${listingId}/badge-requests`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });

        return {
          ...response,
          badgeRequests: response.badgeRequests.map(request => ({
            ...request,
            createdAt: new Date(request.createdAt),
          })),
        };
      },
      getHashtags: async (listingId: number) => {
        return apiWrapper<ListingGetHashtagsResponse>({
          url: `/api/listings/${listingId}/hashtags`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      updateHashtags: async ({
        listingId,
        ...body
      }: ListingUpdateHashtagsVars) => {
        return apiWrapper<ListingUpdateHashtagsResponse>({
          url: `/api/listings/${listingId}/hashtags`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },

      getBadges: async (listingId: number) => {
        return apiWrapper<ListingGetBadgesResponse>({
          url: `/api/listings/${listingId}/badges`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      updateBadges: async ({listingId, ...body}: ListingUpdateBadgesVars) => {
        return apiWrapper<ListingUpdateBadgesResponse>({
          url: `/api/listings/${listingId}/badges`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      get: async (listingId: number) => {
        return apiWrapper<ListingGetResponse>({
          url: `/api/listings/${listingId}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      create: async (body: ListingCreateVars) => {
        return apiWrapper<ListingCreateResponse>({
          url: '/api/listings',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      update: async ({listingId, ...body}: ListingUpdateVars) => {
        return apiWrapper<ListingUpdateResponse>({
          url: `/api/listings/${listingId}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      suggestion: async (body: ListingSuggestionVars) => {
        return apiWrapper<ListingSuggestionResponse>({
          url: '/api/listings/suggestion',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      pagination: async (params: ListingPaginationVars) => {
        return apiWrapper<ListingPaginationResponse>({
          url: '/api/listings',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
    },
    listmageProfile: {
      get: async (listingId: number) => {
        return apiWrapper<ListingGetResponse>({
          url: `/api/listmage-profile/${listingId}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
    },
    badges: {
      requests: {
        pagination: async (params: BadgeRequestPaginationVars) => {
          const response = await apiWrapper<BadgeRequestPaginationResponse>({
            url: '/api/badges/requests',
            onApiError,
            method: 'GET',
            params: removeUndefinedFields(params),
            accessToken: getAccessToken(),
          });

          return {
            ...response,
            badgeRequests: response.badgeRequests.map(request => ({
              ...request,
              createdAt: new Date(request.createdAt),
            })),
          };
        },
        get: async (badgeRequestId: number) => {
          const request = await apiWrapper<BadgeRequestGetResponse>({
            url: `/api/badges/requests/${badgeRequestId}`,
            onApiError,
            method: 'GET',
            accessToken: getAccessToken(),
          });

          return {...request, createdAt: new Date(request.createdAt)};
        },
        update: async ({badgeRequestId, ...body}: BadgeRequestUpdateVars) => {
          const request = await apiWrapper<BadgeRequestUpdateResponse>({
            url: `/api/badges/requests/${badgeRequestId}`,
            onApiError,
            method: 'PUT',
            body,
            accessToken: getAccessToken(),
          });

          return {...request, createdAt: new Date(request.createdAt)};
        },
        create: async (body: BadgeRequestCreateVars) => {
          const request = await apiWrapper<BadgeRequestCreateResponse>({
            url: '/api/badges/requests',
            onApiError,
            method: 'POST',
            body,
            accessToken: getAccessToken(),
          });

          return {...request, createdAt: new Date(request.createdAt)};
        },
      },

      autocomplete: async ({text}: BadgeAutocompleteVars) => {
        return apiWrapper<BadgeAutocompleteResponse>({
          url: '/api/badges/autocomplete',
          onApiError,
          method: 'GET',
          params: {q: text},
          accessToken: getAccessToken(),
        });
      },
      pagination: async (params: BadgePaginationVars) => {
        return apiWrapper<BadgePaginationResponse>({
          url: '/api/badges',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
      get: async (badgeId: number) => {
        return apiWrapper<BadgeGetResponse>({
          url: `/api/badges/${badgeId}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      create: async (body: BadgeCreateVars) => {
        return apiWrapper<BadgeCreateResponse>({
          url: '/api/badges',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      update: async ({badgeId, ...body}: BadgeUpdateVars) => {
        return apiWrapper<BadgeUpdateResponse>({
          url: `/api/badges/${badgeId}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
    },
    hashtags: {
      updateSubHashtags: async ({hashtagId, ...body}: SubHashtagUpdateVars) => {
        return apiWrapper<SubHashtagUpdateResponse>({
          url: `/api/hashtags/${hashtagId}/sub-hashtags`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      getSubHashtags: async (hashtagId: number) => {
        return apiWrapper<SubHashtagGetResponse>({
          url: `/api/hashtags/${hashtagId}/sub-hashtags`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      autocomplete: async ({text}: HashtagAutocompleteVars) => {
        return apiWrapper<HashtagAutocompleteResponse>({
          url: '/api/hashtags/autocomplete',
          onApiError,
          method: 'GET',
          params: {q: text},
          accessToken: getAccessToken(),
        });
      },
      pagination: async (params: HashtagPaginationVars) => {
        return apiWrapper<HashtagPaginationResponse>({
          url: '/api/hashtags',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
      get: async (hashtagId: number) => {
        return apiWrapper<HashtagGetResponse>({
          url: `/api/hashtags/${hashtagId}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      create: async (body: HashtagCreateVars) => {
        return apiWrapper<HashtagCreateResponse>({
          url: '/api/hashtags',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      update: async ({hashtagId, ...body}: HashtagUpdateVars) => {
        return apiWrapper<HashtagUpdateResponse>({
          url: `/api/hashtags/${hashtagId}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
    },
    search: {
      autocomplete: async ({text}: {text: string}) => {
        return apiWrapper<AutocompleteResponse>({
          url: '/api/search/autocomplete',
          onApiError,
          method: 'GET',
          params: {q: text},
        });
      },
      menuItems: async () => {
        return apiWrapper<MenuItemsResponse>({
          url: '/api/search/menu-items',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      // hashtagsAndListings: async (params: {text?: string}) => {
      hashtagsAndListings: async (params: PopularListingsPaginationVars) => {
        return apiWrapper<HashtagsAndListingsResponse>({
          url: '/api/search/hashtags-and-listings',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
      // hashtagsAndListings: async (params: {text?: string}) => {
      badgeListings: async (params: PopularListingsPaginationVars) => {
        return apiWrapper<PopularListingsPaginationResponse>({
          url: '/api/search/badge-listings',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
      subhashtags: async (params: SubhashtagPaginationVars) => {
        return apiWrapper<SubHashtagsResponse>({
          url: '/api/search/sub-hashtags',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
        });
      },
      popularHashtags: async (params: PaginationVars) => {
        return apiWrapper<PopularHashtagsResponse>({
          url: '/api/search/popular-hashtags',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
        });
      },
      popularListingsPagination: async (
        params: PopularListingsPaginationVars,
      ) => {
        return apiWrapper<PopularListingsPaginationResponse>({
          url: '/api/search/popular-listings',
          onApiError,
          params: removeUndefinedFields(params),
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
    },
    siteSettings: {
      get: async (siteSettingsId: string) => {
        return apiWrapper<SiteSettingsItem>({
          url: `/api/site-settings/${siteSettingsId}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      create: async (body: AddSiteSettingsItem) => {
        return apiWrapper<SiteSettingsItem>({
          url: '/api/site-settings',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      update: async ({siteSettingsId, ...body}: SiteSettingsUpdateVars) => {
        return apiWrapper<any>({
          url: `/api/site-settings/${siteSettingsId}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      pagination: async (params: SiteSettingsPaginationVars) => {
        return apiWrapper<SiteSettingsItem[]>({
          url: '/api/site-settings',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
      count: async (params: SiteSettingsCountPaginationVars) => {
        return apiWrapper<CountResponse>({
          url: '/api/site-settings/count',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(params),
          accessToken: getAccessToken(),
        });
      },
    },
    faqs: {
      /* Get All FAQs */
      get: async () => {
        return await apiWrapper<any>({
          url: '/api/faqs',
          onApiError,
          method: 'GET',
          params: {
            offset: 0,
            limit: 100,
            skip: 0,
            order: 'settingName',
            where: {
              additionalProp1: {},
            },
            fields: {
              id: true,
              question: true,
              answer: true,
            },
          },
          accessToken: getAccessToken(),
        });
      },
    },
    faq: {
      /* Update a Question */
      update: async ({faqId, ...body}: any) => {
        return apiWrapper<any>({
          url: `/api/faqs/${faqId}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create a new Question */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/faqs',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete a Question */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/faqs/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    rightsholders: {
      /* Get All Rightsholders */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholders',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
      /* Get Rightsholders Pagination */
      pagination: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholders/pagination',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    rightsholder: {
      /* Get Rightsholder */
      get: async (id: number) => {
        return apiWrapper<any>({
          url: `/api/rightsholders/${id}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      /* Update Rightsholder */
      update: async ({rightsholderId, ...body}: any) => {
        return apiWrapper<any>({
          url: `/api/rightsholders/${rightsholderId}`,
          onApiError,
          method: 'PUT',
          body: {
            ...body,
            id: rightsholderId,
          },
          accessToken: getAccessToken(),
        });
      },
      /* Create Rightsholder */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholders',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Rightsholder */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/rightsholders/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
      /* Upload Image */
      upload: async ({treatment, file}: RightsholderImageUploadVars) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiWrapper<RightsholderImageUploadResponse>({
          url: '/api/rightsholderimages',
          onApiError,
          method: 'POST',
          params: {treatment},
          body: formData,
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    properties: {
      /* Get All Properties */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/properties',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
      /* Get Properties Pagination */
      pagination: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/properties/pagination',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    property: {
      /* Get Property */
      get: async (id: number) => {
        return apiWrapper<ListingGetResponse>({
          url: `/api/properties/${id}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      /* Update Property */
      update: async ({propertyId, ...body}: any) => {
        return apiWrapper<any>({
          url: `/api/properties/${propertyId}`,
          onApiError,
          method: 'PUT',
          body: {
            ...body,
            id: propertyId,
          },
          accessToken: getAccessToken(),
        });
      },
      /* Create Property */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/properties',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Property */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/properties/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    propertylinks: {
      /* Get All Propertylinks */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/propertylinks',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    propertylink: {
      /* Update Propertylink */
      update: async ({...body}: any) => {
        return apiWrapper<any>({
          url: `/api/propertylinks/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Propertylink */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/propertylinks',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Propertylink */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/propertylinks/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    rightsholderlinks: {
      /* Get All Rightsholderlinks */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholderlinks',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    rightsholderlink: {
      /* Update Rightsholderlink */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/rightsholderlinks/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Rightsholderlink */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholderlinks',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Rightsholderlink */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/rightsholderlinks/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    paymenttypes: {
      /* Get Paymenttypes */
      get: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/paymenttypes',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    paymenttype: {
      /* Update Paymenttype */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/paymenttypes/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Paymenttype */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/paymenttypes',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Paymenttype */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/paymenttypes/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
      /* Init Paymenttype */
      init: async (body: any) => {
        return apiWrapper<any>({
          url: '/api/paymenttypes/init',
          onApiError,
          method: 'POST',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    expenses: {
      /* Get Expenses */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/expenses',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    expense: {
      /* Update Expense */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/expenses/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Expense */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/expenses',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Expense */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/expenses/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    advances: {
      /* Get Advances */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/advances',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    advance: {
      /* Update Advance */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/advances/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Advance */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/advances',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Advance */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/advances/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    isbn: {
      /* Get ISBN */
      get: async ({isbn}: any) => {
        return await apiOuterWrapper<any>({
          url: 'https://www.googleapis.com/books/v1/volumes',
          onApiError,
          method: 'GET',
          params: {q: `isbn:${isbn}`},
        });
      },
    },
    covers: {
      upload: async ({treatment, file}: ImageUploadVars) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiWrapper<ImageUploadResponse>({
          url: '/api/covers',
          onApiError,
          method: 'POST',
          params: {treatment},
          body: formData,
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    companySettings: {
      /* Get All companySettings */
      get: async () => {
        return await apiWrapper<any>({
          url: '/api/companysettings',
          onApiError,
          method: 'GET',
          params: {
            offset: 0,
            limit: 100,
            skip: 0,
            where: {
              additionalProp1: {},
            },
            fields: {
              companyName: true,
              primaryImprint: true,
              tagLine: true,
              dateEstablished: true,
              contactName: true,
              email: true,
              phone: true,
              address1: true,
              address2: true,
              city: true,
              state: true,
              zip: true,
              country: true,
              timeZone: true,
              baseCurrency: true,
              minimumPayout: true,
              publisherRoyaltyShare: true,
              autoPropertyCreate: true,
              autoRhCreate: true,
              autoSplit: true,
              withholdTaxes: false,
              allowAuthorLoyaltyEmails: true,
              twitterHandle: true,
              website: true,
              facebookPage: true,
              instagram: true,
              tikTok: true,
              podcast: true,
              amazonAffiliateCode: true,
              taxpayerId: true,
              id: true,
            },
          },
          accessToken: getAccessToken(),
        });
      },
    },
    companySetting: {
      /* Update CompanySetting */
      update: async ({...body}: any) => {
        return apiWrapper<any>({
          url: `/api/companysettings/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create CompanySetting */
      create: async ({...body}: any) => {
        delete body.id;

        return await apiWrapper<any>({
          url: '/api/companysettings',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete CompanySetting */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/companysettings/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    logos: {
      upload: async ({treatment, file}: ImageUploadVars) => {
        const formData = new FormData();
        formData.append('file', file);

        return apiWrapper<ImageUploadResponse>({
          url: '/api/logos',
          onApiError,
          method: 'POST',
          params: {treatment},
          body: formData,
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    subscriptions: {
      get: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/subscriptions',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/subscriptions',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      delete: async ({id, ...body}: any) => {
        return await apiWrapper<any>({
          url: `/api/subscriptions/${id}`,
          onApiError,
          method: 'DELETE',
          body,
          accessToken: getAccessToken(),
        });
      },
      managePaymentType: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/managePaymentType',
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
    },
    balances: {
      /* Get Balances */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/balances',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    balance: {
      /* Update Balances */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/balances/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Balances */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/balances',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Balances */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/balances/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    linkshorteners: {
      /* Get Link Shorteners */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/linkshorteners',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    linkshortener: {
      /* Update Link Shortener */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/linkshortener/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Link Shortener */
      create: async (body: any) => {
        console.log('LINK SHORTENER BODY: ', body);
        return await apiWrapper<any>({
          url: '/api/linkshortener',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Link Shortener */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/linkshortener/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
      /* Get Link Shortener Info */
      info: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/linkshortener/info',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    payments: {
      /* Get Payments */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/payments',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    payment: {
      /* Update Payments */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/payments/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Payments */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/payments',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Payments */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/payments/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    taxes: {
      /* Get All Taxes */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/taxrates',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    tax: {
      /* Update Tax */
      update: async ({...body}: any) => {
        const id = body.id;
        return apiWrapper<any>({
          url: `/api/taxrates/${id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Tax */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/taxrates',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Tax */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/taxrates/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    currencycountrycodes: {
      /* Get All Currency Country Codes */
      get: async () => {
        return await apiWrapper<any>({
          url: '/api/currencycountrycodes',
          onApiError,
          method: 'GET',
          params: {
            currencycode: true,
            countrycode2: true,
            countrycode3: true,
            id: true,
          },
          accessToken: getAccessToken(),
        });
      },
      /* Get All Taxes Exclude By Key */
      getExcludebykey: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/currencycountrycodes/excludebykey',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    companysettingsdelegation: {
      /* Get Company Settings Delegation */
      get: async (companySettingsId?: any) => {
        const params = companySettingsId ? {companySettingsId} : {};

        return await apiWrapper<any>({
          url: '/api/companysettingsdelegation',
          onApiError,
          method: 'GET',
          params,
          accessToken: getAccessToken(),
        });
      },
      /* Update Company Settings Delegation */
      update: async (body: any) => {
        return apiWrapper<any>({
          url: `/api/companysettingsdelegation/${body.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Company Settings Delegation */
      create: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/companysettingsdelegation',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Company Settings Delegation */
      delete: async (body: any) => {
        return await apiWrapper<any>({
          url: `/api/companysettingsdelegation/${body?.id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    knowledgebase: {
      /* Get All Knowledge Bases */
      get: async (categoryId?: any) => {
        const params = categoryId ? {categoryId} : {};

        return await apiWrapper<any>({
          url: '/api/knowledgebase',
          onApiError,
          method: 'GET',
          params,
          accessToken: getAccessToken(),
        });
      },
      /* Get Pagination Knowledge Bases */
      pagination: async (textSearch?: any) => {
        const params = textSearch ? {textSearch} : {};

        return await apiWrapper<any>({
          url: '/api/knowledgebase/pagination',
          onApiError,
          method: 'GET',
          params,
          accessToken: getAccessToken(),
        });
      },
      /* Update Knowledge Bases */
      update: async ({id, ...body}: any) => {
        return apiWrapper<any>({
          url: `/api/knowledgebase/${id}`,
          onApiError,
          method: 'PUT',
          body: {
            ...body,
            id,
          },
          accessToken: getAccessToken(),
        });
      },
      /* Create Knowledge Bases */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/knowledgebase',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Knowledge Bases */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/knowledgebase/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    knowledgebasecategories: {
      /* Get All Knowledge Bases Categories */
      get: async (categoryId?: any) => {
        const params = categoryId ? {categoryId} : {};

        return await apiWrapper<any>({
          url: '/api/knowledgebasecategories',
          onApiError,
          method: 'GET',
          params,
          accessToken: getAccessToken(),
        });
      },
      /* Update Knowledge Bases Categories */
      update: async ({id, ...body}: any) => {
        return apiWrapper<any>({
          url: `/api/knowledgebasecategories/${id}`,
          onApiError,
          method: 'PUT',
          body: {
            ...body,
            id: id,
          },
          accessToken: getAccessToken(),
        });
      },
      /* Create Knowledge Bases Categories */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/knowledgebasecategories',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Knowledge Bases Categories */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/knowledgebasecategories/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    uploadfiles: {
      /* Upload Files */
      create: async (input: any) => {
        return await apiWrapper<any>({
          url: '/api/importerfile',
          onApiError,
          method: 'POST',
          body: input.data,
          params: {userId: input?.userId || ''},
          isFileUpload: true,
          accessToken: getAccessToken(),
        });
      },
    },
    marketingrates: {
      /* Get All Marketing Rates */
      get: async ({...body}) => {
        return await apiWrapper<any>({
          url: '/api/marketingrates',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    marketingrate: {
      /* Update Marketing Rate */
      update: async ({...body}: any) => {
        return apiWrapper<any>({
          url: `/api/marketingrates/${body?.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Marketing Rate */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/marketingrates',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Marketing Rate */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/marketingrates/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    manualsalesentries: {
      /* Get All Manual Sales Entries */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/manualsalesentry',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
    },
    manualsalesentry: {
      /* Get Manual Sales Entry */
      get: async (id: number) => {
        console.log('manualsalesentries GET ONE BODY: ', id);
        return apiWrapper<ListingGetResponse>({
          url: `/api/manualsalesentry/${id}`,
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
        });
      },
      /* Update Manual Sales Entry */
      update: async ({...body}: any) => {
        return apiWrapper<any>({
          url: `/api/manualsalesentry/${body?.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Manual Sales Entry */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/manualsalesentry',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Manual Sales Entry */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/manualsalesentry/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    producttypes: {
      /* Get All Product Types */
      get: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/producttypes',
          onApiError,
          method: 'GET',
          params: removeUndefinedFields(body),
          accessToken: getAccessToken(),
        });
      },
      /* Update Product Type */
      update: async ({...body}: any) => {
        return apiWrapper<any>({
          url: `/api/producttypes/${body?.id}`,
          onApiError,
          method: 'PUT',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Create Product Type */
      create: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/producttypes',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Delete Product Type */
      delete: async ({id}: any) => {
        return await apiWrapper<any>({
          url: `/api/producttypes/${id}`,
          onApiError,
          method: 'DELETE',
          accessToken: getAccessToken(),
        });
      },
    },
    propertytotal: {
      /* Get Property Total */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/propertytotal',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    pendingtotal: {
      /* Get Pending Total */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/pending-total',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    propertymonthyear: {
      /* Get Property Monthyear */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/propertymonthyear',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    rightsholdertotal: {
      /* Get Rightsholder Total */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholdertotal',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
      /* Send PDF report to email */
      sendPDFreportToEmail: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/sendRightsholderPDFreportToEmail',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
      /* Send PDF report to email */
      sendRightsholderPDFreportForAllEmails: async (body: any) => {
        return await apiWrapper<any>({
          url: '/api/sendRightsholderPDFreportForAllEmails',
          onApiError,
          method: 'POST',
          body,
          accessToken: getAccessToken(),
        });
      },
    },
    rightsholdermonthyear: {
      /* Get Rightsholder Monthyear */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/rightsholdermonthyear',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    retailertotal: {
      /* Get Retailer Total */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/retailertotal',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    retailermonthyear: {
      /* Get Retailer Monthyear */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/retailermonthyear',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    channeltotal: {
      /* Get Channel Total */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/channeltotal',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    channelmonthyear: {
      /* Get Channel Monthyear */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/channelmonthyear',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    overviewtotal: {
      /* Get Overview Total */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/overviewtotal',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    latestdatebychannel: {
      /* Get Latest Date By Channel */
      get: async ({...body}: any) => {
        return await apiWrapper<any>({
          url: '/api/latestdatebychannel',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        });
      },
    },
    topsalestotal: {
      /* Pay Top Sales Total */
      pay: async ({...body}) => {
        return await apiWrapper({
          url: '/api/topsalestotal/pay',
          onApiError,
          method: 'POST',
          accessToken: getAccessToken(),
          body,
        });
      },
      /* Accrue Top Sales Total */
      accrue: async ({...body}) => {
        return await apiWrapper({
          url: '/api/topsalestotal/accrue',
          onApiError,
          method: 'POST',
          accessToken: getAccessToken(),
          body,
        })
      },
      /* Check Duplicate Payments */
      checkduplicatepayments: async ({...body}) => {
        return await apiWrapper({
          url: '/api/checkduplicatepayments',
          onApiError,
          method: 'GET',
          accessToken: getAccessToken(),
          params: removeUndefinedFields(body),
        })
      },
    },
  };
}
